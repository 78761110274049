.projects-heading {
    font-family: Satoshi-Medium, sans-serif;
    font-size: 80px;
    color: #879777;
    animation-duration: 3.3s;
    animation-name: projectHeadingSlideIn;
    margin-right: 15vw;
}

@keyframes projectHeadingSlideIn {
    from {
        opacity: 0;
        margin-right: 5vw;
    }

    to {
        opacity: 1;
        margin-right: 15vw;
    }
}

@media (max-width:1440px) {
    .projects-heading {
        font-size: 60px;
    }
}

@media (max-width:1024px) {
    .projects-heading {
        font-size: 45px;
    }   
}

@media (max-width:500px) {
    .projects-heading {
        font-size: 20px;
    }
}