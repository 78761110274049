.contact-heading-wrapper {
    font-family: Satoshi-Medium, sans-serif;
    font-size: 3vw;
    color: #879777;
    animation-duration: 2s;
    animation-name: contactHeadingSlideIn;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -20vh;
}

@keyframes contactHeadingSlideIn {
    from {
        margin-right: 60%;
        opacity: 0;
    }

    to {
        opacity: 1;
        margin-right: 0%;
    }
}

@media (max-width: 800px) {
    .contact-heading-wrapper {
        font-size: 4vw;
    }
    
}

@media (max-width: 600px) {
    .contact-heading-wrapper {
        top: -24vh;
    }
}

@media (max-width: 450px) {
    .contact-heading-wrapper {
        font-size: 4.3vw;
    }
}