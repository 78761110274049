.side-brand-wrapper {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-side {
    transform: rotate(270deg);
    animation-duration: 2.5s;
    animation-name:leftBrandSlideUp;
}

.right-side {
    transform: rotate(90deg);
    animation-duration: 2.5s;
    animation-name:rightBrandSlideDown;
}

.side-brand-name {
    font-family: Satoshi-Light-Italic, sans-serif;
    font-size: 20px;
    opacity: 60%;
    white-space: nowrap;
}

.brand-name-red {
    color: #B8A2A2;
}

.brand-name-green {
    color: #ADB8A2;
}

.side-brand-line {
    width: 100%;
    height: 2.5px;
    opacity: 15%;
    margin-top: 2vw;
}

.brand-line-red {
    background-color: #B8A2A2;
}

.brand-line-green {
    background-color: #ADB8A2;
}

@keyframes leftBrandSlideUp {
    0% {
        margin-top: 100%;
        opacity: 0;
    }
    
    40% {
        margin-top: 100%;
        opacity: 0;
    }

    100% {
        margin-top: 0%;
        opacity: 1;
    }
}

@keyframes rightBrandSlideDown {
    0% {
        margin-bottom: 100%;
        opacity: 0;
    }
    
    40% {
        margin-bottom: 100%;
        opacity: 0;
    }

    100% {
        margin-bottom: 0%;
        opacity: 1;
    }
}

@media (max-width:1024px) {
    .side-brand-name {
        font-size: 15px;
    }
}

@media (max-width:768px) {
    .side-brand-name {
        font-size: 10px;
    }
}

@media (max-width:600px) {
    .side-brand-wrapper {
        display: none;
    }
}