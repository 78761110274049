.contact-back {
    height: 100vh;
    width: 100vw;
    display: flex;
    overflow: hidden;
    background-color: #F5F5F5;
}

.contact-side-container {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-middle-container {
    height: 100%;
    width: 60%;
    padding-top: 10vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contact-decorative-text {
    position: fixed;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 5.5vw;
    color: #ADB8A2;
    transform: rotate(90deg);
    top: 43%;
    left: 70%;
    z-index: 0;
    white-space: nowrap;
    opacity: 0.3;
    animation-name:contactDecorativeTextFadeIn;
    animation-duration:3s;
}

@keyframes contactDecorativeTextFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.3;
    }
}