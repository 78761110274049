@font-face {
  font-family: Satoshi-Bold;
  font-weight: bold;
  src: url("./assets/fonts/Satoshi/Satoshi-Bold.woff") format("woff");
}

@font-face {
  font-family: Satoshi-Regular;
  src: url("./assets/fonts/Satoshi/Satoshi-Regular.woff") format("woff");
}

@font-face {
  font-family: Satoshi-Italic;
  src: url("./assets/fonts/Satoshi/Satoshi-Italic.woff") format("woff");
}

@font-face {
  font-family: Satoshi-Light-Italic;
  src: url('./assets/fonts/Satoshi/Satoshi-LightItalic.woff') format("woff");
}

@font-face {
  font-family: Satoshi-Medium;
  src: url('./assets/fonts/Satoshi/Satoshi-Medium.woff') format("woff");
}

@font-face {
  font-family: Sonder;
  src: url("./assets/fonts/Sonder/SonderRegular.woff") format("woff");
}

@font-face {
  font-family: Bagnard;
  src: url("./assets/fonts/Bagnard/BagnardSans.woff") format("woff");
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: #F5F5F5;
}
