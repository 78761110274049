.social-row-container {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20vh;
    transition: 2s;
}

.contact-social-icon {
    cursor: pointer;
    animation-name: socialIconFadeIn;
    animation-duration: 5s;
}

@keyframes socialIconFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.contact-social-icon:hover {
    transition: 2s;
    scale: 1.2;
}
@media (max-width: 1030px) {
    .contact-social-icon {
        transform: scale(2.2);
    }
}

@media (max-width: 800px) {
    .contact-social-icon {
        transform: scale(3);
    }
}

@media (max-width:600px) {
    .social-row-container {
        top: 25vh;
    }

    .contact-social-icon {
        transform: scale(5);
    }
}