.about-body-wrapper {
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: space-around;
}

.about-body {
    font-family: Satoshi-Medium, sans-serif;
    font-size: 1.1vw;
    color: #977777;
}

.career {
    animation-duration: 3s;
    animation-name: careerBlurbFadeIn;
}

.racing {
    text-align: right;
    animation-duration: 4.5s;
    animation-name: racingBlurbFadeIn;
}

.side-hustles {
    animation-duration: 5s;
    animation-name: sideHustlesBlurbFadeIn;
}

@keyframes careerBlurbFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes racingBlurbFadeIn {
    0% {
        opacity: 0;
    }

    65% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes sideHustlesBlurbFadeIn {
    0% {
        opacity: 0;
    }

    45% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media (max-width:780px) {
    .about-body {
        font-size: 1.5vh;
    }
}