.hero-background {
    height: 100%;
    width: 100%;
    background-color: #F5F5F5;
    display: flex;
    overflow: hidden;
}

.hero-text-wrapper {
    position: absolute;
    top:50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-text-hello {
    font-family: Sonder, sans-serif;
    font-size: 300px;
    color: #B8A2A2;
    opacity: 0.2;
    position: absolute;
    right: 0.0005em;
    animation-duration: 2.5s;
    animation-name: heroHelloFadeIn;
}

.hero-text-name {
    z-index:10;
    opacity: 1;
    font-family: Satoshi-Bold, sans-serif;
    font-size: 125px;
    color: #977777;
    position: absolute;
    white-space: nowrap;
    animation-duration: 4s;
    animation-name: heroNameFadeIn;
}

.hero-tagline-typer {
    font-family: Satoshi-Regular, sans-serif;
    font-size: 75px;
    position: absolute;
    top: 60%;
    left: 40%;
    color: #7C5F5F;
    animation-duration: 3.5s;
    animation-name: heroTaglineFadeIn;
}

.hero-back-diagonal {
    width: 100%;
    height: 43%;
    opacity: 0.46;
    position: relative;
    top: 11%;
    background-color: #AD9494;
    align-self: flex-end;
    transform: skewY(-6deg);
    animation-duration: 1s;
    animation-name: heroBackSlideRight;
    z-index: 0;
}

@keyframes heroBackSlideRight {
    from {
        margin-right: 100%;
    }

    to {
        margin-right: 0%;
    }
}

@keyframes heroHelloFadeIn {
    from {
        opacity: 0%;
    }

    to {
        opacity: 0.2;
    }
}

@keyframes heroTaglineFadeIn {
    from {
        opacity: 0;
    }
    
    to {
        opacity: 1;
    }
}

@keyframes heroNameFadeIn {
    0% {
        opacity:0
    }

    26% {
        opacity:0
    }

    100%{
        opacity:1
    }
}

@media (max-width:1800px) {
    .hero-text-hello {
        font-size: 275px;
    }

    .hero-text-name {
        font-size: 110px;
    }

    .hero-tagline-typer {
        font-size: 60px;
    }
}

@media (max-width:1600px) {
    .hero-text-hello {
        font-size: 250px;
    }

    .hero-text-name {
        font-size: 100px;
    }

    .hero-tagline-typer {
        font-size: 55px;
    }
}

@media (max-width:1440px) {
    .hero-text-hello {
        font-size: 225px;
    }

    .hero-text-name {
        font-size: 90px;
    }

    .hero-tagline-typer {
        font-size: 45px;
    }
}

@media (max-width:1250px) {
    .hero-text-hello {
        font-size: 210px;
    }

    .hero-text-name {
        font-size: 85px;
    }

    .hero-tagline-typer {
        font-size: 40px;
    }
}

@media (max-width:1024px) {
    .hero-text-hello {
        font-size: 175px;
    }

    .hero-text-name {
        font-size: 75px;
    }

    .hero-tagline-typer {
        font-size: 35px;
    }
    
    .hero-back-diagonal {
        top: 9.5%;
    }
}

@media (max-width:768px) {
    .hero-text-hello {
        font-size: 130px;
    }

    .hero-text-name {
        font-size: 55px;
    }

    .hero-tagline-typer {
        font-size: 25px;
    }

    .hero-back-diagonal {
        top: 8%;
    }
}

@media (max-width:590px) {
    .hero-text-hello {
        font-size: 100px;
    }

    .hero-text-name {
        font-size: 45px;
    }

    .hero-tagline-typer {
        font-size: 23px;
    }

    .hero-back-diagonal {
        top: 7%;
    }
}

@media (max-width:485px) {
    .hero-text-hello {
        font-size: 90px;
    }

    .hero-text-name {
        font-size: 35px;
    }

    .hero-tagline-typer {
        font-size: 18px;
        top: 55%;
        left: 31%;;
    }

    .hero-back-diagonal {
        top: 8%;
        height: 50%;
    }
}

@media (max-width: 400px) {
    .hero-text-hello {
        font-size: 75px;
    }

    .hero-text-name {
        font-size: 30px;
    }

    .hero-tagline-typer {
        font-size: 15px;
        top: 54%;
        left: 30%;
    }

    .hero-back-diagonal {
        top: 7%;
    }
}

@media (max-width: 320px) {
    .hero-text-hello {
        font-size: 60px;
    }

    .hero-text-name {
        font-size: 24px;
    }

    .hero-tagline-typer {
        font-size: 12px;
        top: 53.5%;
        left: 30%;

    }

    .hero-back-diagonal {
        top: 5.5%;
    }
}