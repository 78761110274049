.nav-container {
    width: 100vw;
    overflow: hidden;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    flex-direction: column;
    z-index: 5;
}

.nav-wrapper {
    width: 60%;
    height: 22px;
    display: flex;
    justify-content: space-evenly;
    animation-duration: 2s;
    animation-name: navButtonFadeIn;
    flex-wrap: nowrap;
}

.nav-red a {
    color: #B8A2A2;
}

.nav-green a {
    color: #ADB8A2;
}

.nav-line {
    width: 28%;
    height: 2.5px;
    opacity: 0.15;
    margin-top: 4vh;
    animation-duration: 2s;
    animation-name: navLineFadeIn;
}

#nav-line-red {
    background-color: #B8A2A2;
}

#nav-line-green {
    background-color: #ADB8A2;
}

.nav-button a {
    font-family: Satoshi-Italic, sans-serif;
    font-size: 20px;
    text-decoration: none;
    white-space: nowrap;
}

.nav-initials-logo a{
    font-family: Bagnard, sans-serif;
    font-size: 40px;
    position: fixed;
    text-decoration: none;
    top: 1%;
    left: 1%;
    animation-duration: 4s;
    animation-name: logoFadeIn;
    z-index: 20;
}

#nav-logo-red a {
    color: #B8A2A2;
}

#nav-logo-green a {
    color: #ADB8A2;
}

.nav-container-away {
    width: 100vw;
    position: fixed;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
    animation-duration: 2s;
    animation-name: navContainerAwayMorph;
}

.nav-wrapper-away {
    display: flex;
    flex-wrap: nowrap;
    width: 95%;
    justify-content: space-evenly;
    animation-duration: 2s;
    animation-name: navWrapperAwayMorph;
}

.nav-line-away {
    width: 40%;
    height: 2.5px;
    background-color: #B8A2A2;
    opacity: 0.15;
    margin-top: 2vh;
    animation-duration: 2s;
    animation-name: navLineAwayMorph;
}

@keyframes navButtonFadeIn {
    from {
        opacity: 0;
        width: 14%;
    }

    30% {
        opacity: 0;
    }

    to {
        opacity: 1;
        width: 60%;
    }
}

@keyframes navLineFadeIn {
    from {
        opacity: 0;
        width: 0%;
    }

    to {
        opacity: 0.15;
        width: 28%;
    }

}

@keyframes navContainerAwayMorph {
    from {
        padding-top: 5vh;
    }

    to {
        padding-top: 2vh;
    }
}

@keyframes navWrapperAwayMorph {
    from {
        width: 60%;
    }

    to {
        width: 95%;
    }
}

@keyframes navLineAwayMorph {
    from {
        width: 28%;
        margin-top: 4vh;
    }

    to {
        width: 40%;
        margin-top: 2vh;
    }
}

@keyframes logoFadeIn {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media (max-width:1440px) {
    .nav-wrapper {
        width: 65%;
    }

    .nav-line {
        width: 31%;
    }

    .nav-button a {
        font-size: 18px;
    }

    .nav-initials-logo a {
        font-size: 35px;
    }

    @keyframes navButtonFadeIn {
        from {
            opacity: 0;
            width: 14%;
        }
    
        30% {
            opacity: 0;
        }
    
        to {
            opacity: 1;
            width: 65%;
        }
    }

    @keyframes navLineFadeIn {
        from {
            opacity: 0;
            width: 0%;
        }
    
        to {
            opacity: 0.15;
            width: 31%;
        }
    
    }
    
    @keyframes navWrapperAwayMorph {
        from {
            width: 65%;
        }
    
        to {
            width: 95%;
        }
    }
    
    @keyframes navLineAwayMorph {
        from {
            width: 31%;
            margin-top: 4vh;
        }
    
        to {
            width: 40%;
            margin-top: 2vh;
        }
    }
}

@media (max-width:800px) {
    .nav-wrapper {
        width: 70%;
    }

    .nav-line {
        width: 35%;
    }

    .nav-button a {
        font-size: 15px;
    }

    .nav-initials-logo a {
        font-size: 25px;
        top: 1.5%;
        left: 1.5%;
    }

    @keyframes navButtonFadeIn {
        from {
            opacity: 0;
            width: 14%;
        }
    
        30% {
            opacity: 0;
        }
    
        to {
            opacity: 1;
            width: 70%;
        }
    }

    @keyframes navLineFadeIn {
        from {
            opacity: 0;
            width: 0%;
        }
    
        to {
            opacity: 0.15;
            width: 35%;
        }
    
    }

    .nav-line-away {
        width: 45%;
    }

    @keyframes navWrapperAwayMorph {
        from {
            width: 70%;
        }
    
        to {
            width: 95%;
        }
    }
    
    @keyframes navLineAwayMorph {
        from {
            width: 35%;
            margin-top: 4vh;
        }
    
        to {
            width: 45%;
            margin-top: 2vh;
        }
    }
}

@media (max-width:600px) {
    .nav-wrapper {
        width: 75%;
    }

    .nav-line {
        width: 42%;
    }

    .nav-button a {
        font-size: 12px;
    }

    .nav-initials-logo a {
        font-size: 20px;
        top: 2%;
        left: 2%;
    }

    @keyframes navButtonFadeIn {
        from {
            opacity: 0;
            width: 14%;
        }
    
        30% {
            opacity: 0;
        }
    
        to {
            opacity: 1;
            width: 75%;
        }
    }

    @keyframes navLineFadeIn {
        from {
            opacity: 0;
            width: 0%;
        }
    
        to {
            opacity: 0.15;
            width: 42%;
        }
    
    }

    .nav-line-away {
        width: 55%;
    }

    @keyframes navWrapperAwayMorph {
        from {
            width: 75%;
        }
    
        to {
            width: 95%;
        }
    }
    
    @keyframes navLineAwayMorph {
        from {
            width: 42%;
            margin-top: 4vh;
        }
    
        to {
            width: 55%;
            margin-top: 2vh;
        }
    }
}

@media (max-width:400px) {
    .nav-wrapper {
        width: 80%;
    }

    .nav-line {
        width: 45%;
    }

    .nav-button a {
        font-size: 11px;
    }

    .nav-initials-logo a {
        font-size: 15px;
        top: 2%;
        left: 2%;
    }

    @keyframes navButtonFadeIn {
        from {
            opacity: 0;
            width: 14%;
        }
    
        30% {
            opacity: 0;
        }
    
        to {
            opacity: 1;
            width: 80%;
        }
    }

    @keyframes navLineFadeIn {
        from {
            opacity: 0;
            width: 0%;
        }
    
        to {
            opacity: 0.15;
            width: 45%;
        }
    
    }

    .nav-line-away {
        width: 55%;
    }

    @keyframes navWrapperAwayMorph {
        from {
            width: 80%;
        }
    
        to {
            width: 95%;
        }
    }
    
    @keyframes navLineAwayMorph {
        from {
            width: 45%;
            margin-top: 4vh;
        }
    
        to {
            width: 55%;
            margin-top: 2vh;
        }
    }
}