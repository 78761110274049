.project-box-container {
    position: fixed;
    width: 50vw;
    height: 45vh;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    background-color: #ADB8A2;
    animation-duration: 2.3s;
    animation-name:projectBoxFadeIn;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.project-box-screen-no-fade {
    height: 92%;
    width: 96%;
    background-color: #F5F5F5;
    border-radius: 20px;
    opacity: 1;
    animation: none;
}

.project-box-screen {
    height: 92%;
    width: 96%;
    background-color: #F5F5F5;
    border-radius: 20px;
    animation-duration: 2.3s;
    animation-name: projectScreenFadeIn;
}

.project-box-decoration {
    position: absolute;
    color: #ADB8A2;
    top: -32%;
    left: 10%;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 180px;
    opacity: 0.2;
    z-index: -1;
    animation-duration: 2.3s;
    animation-name: projectBoxDecorationFadeIn;
}

.project-screen-home-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen-home-index {
    width: 100%;
    height: 9%;
    display: flex;
    border-bottom: solid #ADB8A2;
    align-items: center;
}

.screen-index-title {
    width: 60%;
    display: flex;
    justify-content: center;
    color: #879777;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
}

.screen-index-size {
    width: 40%;
    display: flex;
    justify-content: center;
    color: #879777;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
}

.screen-home-project-row {
    height: 10%;
    display: flex;
    align-items: center;
    width: 80%;
    border-bottom: solid #ADB8A2 2px;
    transition: 2s;
    animation-duration: 3s;
    animation-name: screenContentFadeIn;
    z-index: 5;
}

.screen-home-project-row-no-fade {
    height: 10%;
    display: flex;
    align-items: center;
    width: 80%;
    border-bottom: solid #ADB8A2 2px;
    transition: 2s;
    z-index: 5s;
}

.project-screen-selected-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-name:projectScreenSelectedFadeIn;
    animation-duration:2s;
}

@keyframes projectScreenSelectedFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.project-selected-top-row {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-selected-back-button {
    width: 30%;
}

.back-button-text {
    font-family: Satoshi-Regular, sans-serif;
    font-size: 3vw;
    color: #ADB8A2;
    margin: 0;
    transform: scale(.5, 1);
    height: 50%;
    width: fit-content;
    cursor: pointer;
}

.back-button-text:hover {
    transition: 1s;
    opacity: 0.5;
}

.project-selected-title {
    width: 70%;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 2vw;
    color: #879777;
}

@keyframes screenContentFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.screen-home-project-row:hover {
    background-color: #adb8a259;
}

.screen-home-project-row-no-fade:hover {
    background-color: #adb8a259;
}

.screen-project-title-wrapper {
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
    color: #879777;
    width: 67%;
    padding-left: 12%;
}

.screen-project-size-wrapper {
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
    color: #879777;
}

.project-selected-content-row {
    display: flex;
    height: 85%;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.project-selected-image-wrapper {
    width: 13vw;
    height: 90%;
    display: flex;
    align-items: center;
}

.project-selected-image-wrapper img {
    height: auto;
    width: 100%;
    border-radius: 10px;
}
.project-selected-body-container {
    width: 65%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.project-selected-description {
    width: 100%;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 0.8vw;
    color: #879777;
}

.project-selected-technologies {
    width: 100%;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 0.8vw;
    color: #879777;
}

.project-selected-links-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.github-link, .deployment-link {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.project-link a {
    font-family: Satoshi-Regular, sans-serif;
    font-size: 0.8vw;
    color: #879777;
}

.project-link a:hover {
    transition: 1s;
    opacity: .5;
}

@keyframes projectBoxFadeIn {
    0% {
        opacity: 0;
        width: 5vw;
        height: 0vh;
    }

    40% {
        height: 45vh;
        width: 5vw;
    }


    100% {
        opacity: 1;
        width: 50vw;
    }
}


@keyframes projectScreenFadeIn {
    from {
        background-color: #ADB8A2;
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    to {
        opacity: 1;
        background-color: #F5F5F5;
    }
}

@keyframes projectBoxDecorationFadeIn {
    from {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 2000px) {
    .project-box-decoration {
        font-size: 130px;
    }
}

@media (max-width: 1440px) {
    .project-box-decoration {
        font-size: 100px;
    }

    .project-box-container {
        width: 60vw;
        height: 50vh;
    }

    .screen-project-title-wrapper {
        font-size: 1.2vw;
    }

    .screen-project-size-wrapper {
        font-size: 1.2vw;
    }

    .screen-index-title {
        font-size: 1.2vw;
    }

    .screen-index-size {
        font-size: 1.2vw;
    }

    .project-selected-title {
        font-size: 2.5vw;
    }

    .project-selected-description {
        font-size: 1.2vw;
    }

    .project-selected-technologies {
        font-size: 1.2vw;
    }

    .project-link a {
        font-size: 1vw;
    }

    @keyframes projectBoxFadeIn {
        0% {
            opacity: 0;
            width: 5vw;
            height: 0vh;
        }
    
        40% {
            height: 50vh;
            width: 5vw;
        }
    
    
        100% {
            opacity: 1;
            width: 60vw;
        }
    }
}

@media (max-width: 1030px) {
    .screen-project-title-wrapper {
        font-size: 1.4vw;
    }

    .screen-project-size-wrapper {
        font-size: 1.4vw;
    }

    .screen-index-title {
        font-size: 1.4vw;
    }

    .screen-index-size {
        font-size: 1.4vw;
    }
}

@media (max-width: 800px) {
    .project-selected-back-button {
        width: auto;
        position: absolute;
        right: 88%;
    }

    .project-selected-title {
        display: flex;
        justify-content: center;
        width: 0;
        white-space: nowrap;
    }

    .project-selected-content-row {
        flex-direction: column;
    }

    .project-selected-image-wrapper {
        height: auto;

        justify-content: center;
    }

    .project-selected-image-wrapper img {
        width: 165%;
    }

    .project-selected-body-container {
        justify-content: space-between;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .project-selected-description {
        font-size: 1.5vw;
    }

    .project-selected-technologies {
        font-size: 1.5vw;
    }

    .project-link a {
        font-size: 1.3vw;
    }

    .screen-project-title-wrapper {
        font-size: 1.9vw;
    }

    .screen-project-size-wrapper {
        font-size: 1.9vw;
    }

    .screen-index-title {
        font-size: 1.9vw;
    }

    .screen-index-size {
        font-size: 1.9vw;
    }

    .project-box-decoration {
        top: -18%;
    }

    .project-box-container {
        width: 65vw;
        height: 55vh;
    }

    @keyframes projectBoxFadeIn {
        0% {
            opacity: 0;
            width: 5vw;
            height: 0vh;
        }
    
        40% {
            height: 55vh;
            width: 5vw;
        }
    
    
        100% {
            opacity: 1;
            width: 65vw;
        }
    }
}

@media (max-width: 600px) {
    .back-button-text {
        font-size: 5vw;
    }

    .project-selected-title {
        font-size: 4vw;
    }

    .project-selected-image-wrapper img {
        width: 250%;
    }

    .project-selected-description {
        font-size: 2.3vw;
    }

    .project-selected-technologies {
        font-size: 2.3vw;
    }

    .project-link a {
        font-size: 2vw;
    }

    .project-box-decoration {
        font-size: 70px;
        top: -12%;
    }

    .screen-project-title-wrapper {
        font-size: 2.8vw;
    }

    .screen-project-size-wrapper {
        font-size: 2.8vw;
    }

    .screen-index-title {
        font-size: 2.8vw;
    }

    .screen-index-size {
        font-size: 2.8vw;
    }

    .project-box-container {
        width: 90vw;
        height: 60vh;
    }

    @keyframes projectBoxFadeIn {
        0% {
            opacity: 0;
            width: 5vw;
            height: 0vh;
        }
    
        40% {
            height: 60vh;
            width: 5vw;
        }
    
    
        100% {
            opacity: 1;
            width: 90vw;
        }
    }
}

@media (max-width: 450px) {
    .back-button-text {
        font-size: 7vw;
        padding-left: 15%;
    }

    .project-selected-title {
        font-size: 5vw;
    }

    .project-selected-image-wrapper img {
        width: 375%;
    }

    .project-selected-description {
        font-size: 2.7vw;
    }

    .project-selected-technologies {
        font-size: 2.7vw;
    }

    .project-link a {
        font-size: 2.6vw;
    }

    .project-box-decoration {
        font-size: 40px;
        top: -5vh;
    }

    .screen-project-title-wrapper {
        font-size: 3.3vw;
    }

    .screen-project-size-wrapper {
        font-size: 3.3vw;
    }

    .screen-index-title {
        font-size: 3.3vw;
    }

    .screen-index-size {
        font-size: 3.3vw;
    }

    .project-box-container {
        height: 78vh;
        top: 56%;
    }
}