.contact-form-container {
    height: 50vh;
    width: 65vw;
    background-color: #adb8a29d;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    animation-name: contactFormFadeIn;
    animation-duration: 2s;
    z-index: 2;
}

@keyframes contactFormFadeIn {
    from {
        opacity: 0;
        width: 30vh;
    }

    to {
        opacity: 1;
        width: 65vw;
    }
    
}

.contact-form-container form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.message-sent-confirmation {
    position: absolute;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 4vw;
    color: #879777;
    opacity: 0.5;
    left: 20vw;
    top: -10vh;
    animation-name: messageConfirmFadeIn;
    animation-duration: 1.5s;
}

@keyframes messageConfirmFadeIn {
    from {
        opacity: 0;
        top: -5vh;
    }

    to {
        opacity: 0.5;
        top: -10vh;
    }
}

.contact-first-row {
    height: 15%;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.contact-name-input {
    height: 50%;
    width: 35%;
    border: none;
    border-radius: 10px;
    background-color: #F5F5F5;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
}

.contact-email-input {
    height: 50%;
    width: 55%;
    border: none;
    border-radius: 10px;
    background-color: #F5F5F5;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
}

.contact-second-row {
    height: 15%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-subject-input {
    height: 50%;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: #F5F5F5;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
}

.contact-third-row {
    height: 60%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-message-input {
    height: 80%;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: #F5F5F5;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 1vw;
    resize: none;
}

.contact-submit-container {
    height: 10%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-submit-button {
    cursor: pointer;
    background-color: #879777;
    color: white;
    height: 60%;
    width: 10%;
    margin-bottom: 20px;
    border: none;
    border-radius: 10px;
    font-family: Satoshi-Regular, sans-serif;
    font-size: 0.8vw;
    transition: 0.5s;
}

.contact-submit-button:hover {
    font-size: 0.9vw;
}

@media (max-width: 1450px) {
    .contact-name-input {
        font-size: 1.2vw;
    }

    .contact-email-input {
        font-size: 1.2vw;
    }

    .contact-subject-input {
        font-size: 1.2vw;
    }

    .contact-message-input {
        font-size: 1.2vw;
    }
}

@media (max-width: 1030px) {
    .contact-name-input {
        font-size: 1.5vw;
    }

    .contact-email-input {
        font-size: 1.5vw;
    }

    .contact-subject-input {
        font-size: 1.5vw;
    }

    .contact-message-input {
        font-size: 1.5vw;
    }

    .contact-submit-button {
        font-size: 1.2vw;
        width: 15%;
    }

    .contact-submit-button:hover {
        font-size: 1.5vw;
    }
}

@media (max-width: 780px) {
    .contact-name-input {
        font-size: 1.8vw;
    }

    .contact-email-input {
        font-size: 1.8vw;
    }

    .contact-subject-input {
        font-size: 1.8vw;
    }

    .contact-message-input {
        font-size: 1.8vw;
    }

    .contact-submit-button {
        font-size: 1.5vw;
        width: 20%;
    }

    .contact-submit-button:hover {
        font-size: 1.8vw;
    }

    .message-sent-confirmation { 
        top: -5vh;
    }
}

@media (max-width: 600px) {
    .contact-form-container {
        height: 55vh;
        width: 80vw;
    }

    .message-sent-confirmation {
        left: 27vw;
    }

    .contact-name-input {
        font-size: 2.5vw;
    }

    .contact-email-input {
        font-size: 2.5vw;
    }

    .contact-subject-input {
        font-size: 2.5vw;
    }

    .contact-message-input {
        font-size: 2.5vw;
    }

    .contact-submit-button {
        font-size: 2vw;
        width: 23%;
    }

    .contact-submit-button:hover {
        font-size: 2.2vw;
    }

    @keyframes contactFormFadeIn {
        from {
            opacity: 0;
            width: 30vh;
        }
    
        to {
            opacity: 1;
            width: 80vw;
        }
        
    }
}

@media (max-width: 450px) {
    .contact-form-container {
        height: 65vh;
        width: 95vw;
    }

    .contact-name-input {
        font-size: 3.5vw;
    }

    .contact-email-input {
        font-size: 3.5vw;
    }

    .contact-subject-input {
        font-size: 3.5vw;
    }

    .contact-message-input {
        font-size: 3.5vw;
    }

    .contact-submit-button {
        font-size: 3vw;
        width: 28%;
    }

    .contact-submit-button:hover {
        font-size: 3.3vw;
    }

    .message-sent-confirmation {
        left: 33vw;
        font-size: 5vw;
    }

    @keyframes contactFormFadeIn {
        from {
            opacity: 0;
            width: 30vh;
        }
    
        to {
            opacity: 1;
            width: 95vw;
        }
        
    }
}